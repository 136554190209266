.icon-boxes .icon-box {
  margin-top: 30px;
}

.icon-boxes .icon-box i {
  font-size: 40px;
  color: #cea058;
  margin-bottom: 10px;
}

.icon-boxes .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.icon-boxes .icon-box p {
  font-size: 15px;
  color: #202020;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .services .content {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .services {
    text-align: center;
  }
}
/* .sigle-service {
  background-color: #cea058;
  color: aliceblue;
} */
