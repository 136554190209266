.section-title h2 {
  border-bottom: 2px solid #ffc451;
}
.section-title p {
  color: #151515;
}
.services .icon-box {
  text-align: center;
  border: 1px solid #ebebeb;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1.2rem;
}

.services .icon-box h4 a {
  color: #151515;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 a:hover {
  color: #ffc451;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  color: #151515;
  font-weight: 400;
}
.more-button {
  margin-top: 0.5rem;
}
.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
}
